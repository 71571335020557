import React, { useEffect, useState } from "react";
import { Table, Button, Select, Spin, Input, Switch } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import useApiRequest from "../../helper/useApiHook";
import EditUserRole from "./EditRole";
import API from "../../helper/api";
import { showErrorToast, showSuccessToast } from "../../helper/toaster";
// import {userImg} from "../../../src/images/user1.svg"
import "./users.scss";

const { Option } = Select;
const { Search } = Input;
export const Users = ({ filters }) => {
	const [users, setUsers] = useState([]);
	const [loader, setLoader] = useState(false);
	const [spinLoader, setSpinLoader] = useState(false);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [modal, setModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);

	const [attraction, setAttraction] = useState(null);
	const [sortField, setSortField] = useState("");
	const [sortOrder, setSortOrder] = useState(1);
	const [byCountries, setByCountries] = useState([]);
	const [byType, setByType] = useState([]);
	const [byTitle, setByTitle] = useState([]);
	const [loadingCall, setLoadingCall] = React.useState(false);
	const [countriesDataTosearch, setCountriesDataToSearch] = useState([]);
	const { data: usersData, makeRequest: fetchUsersData } = useApiRequest();

	useEffect(() => {
		fetchUsersData("/users", "GET", { page: page, limit: 20 });
	}, []);

	useEffect(() => {
		if (!byTitle || !byCountries || !byType) {
			fetchUsersData("/users", "GET", { page: page, limit: 20 });
		}
	}, [byTitle]);

	const onTypeChange = (selectedTypes) => {
		setByType(selectedTypes);

		fetchUsersData("/users", "GET", {
			page: 1,
			limit: 20,
			attraction_types: selectedTypes.map((type) => type.value),
		});
		// setCountriesDataToSearch(countryData);
	};

	// useEffect(() => {
	//   if (countryData) {
	//     setCountriesDataToSearch(countryData);
	//   }
	// }, [countryData]);
	useEffect(() => {
		if (usersData) {
			// console.log(usersData, "----------------------------------");
			const { total, users } = usersData;
			const data = users.map((item) => ({
				id: item._id,
				firstName: item.firstName,
				lastName: item.lastName,
				email: item.email,
				role: item.role,
				// isVerified: item.isVerified,
				accountStatus: item.accountStatus === "active",
				country: item.country ? item.country : "",
				favouriteTeam: item?.favouriteTeams ? item?.favouriteTeams : [],
				// created_by: item.created_by,
				// key: item._id,
				// countryCode: item.country ? item.country.short_name : "",
				// location: item.location,
			}));
			setTotal(total);
			setUsers(data);
		}
	}, [usersData]);

	// const editAttraction = (id) => {
	// 	const data = users.find((item) => id === item.key);
	// 	setUsers(data);
	// 	setModal(!modal);
	// };

	const editUserRole = (id) => {
		const user = users.find((item) => item.id === id);
		setSelectedUser(user);
		setModal(true);
	};

	const handleSaveUserChanges = async (userId, updatedFields) => {
		const updatedStatus = updatedFields.accountStatus ? "active" : "inactive";
		const payload = {
			userId,
			role: updatedFields.role,
			accountStatus: updatedFields.accountStatus ? "active" : "inactive",
		};

		setLoadingCall(userId);

		try {
			await API.patch(`/update-user`, payload);
			fetchUsersData("/users", "GET", { page: page, limit: 20 });

			setUsers((prevUsers) =>
				prevUsers.map((user) =>
					user.id === userId
						? {
								...user,
								...updatedFields,
								accountStatus: updatedStatus === "active",
						  }
						: user
				)
			);

			showSuccessToast("User details updated successfully!");
			setModal(false); // Close the modal after saving changes
		} catch (err) {
			console.error("Error updating user:", err);
			showErrorToast(err.message || "Something went wrong");
		} finally {
			setLoadingCall("");
		}
	};

	const handleChangeAccountStatus = async (data) => {
		const id = data.id;
		const payload = {
			userId: id,
			accountStatus: !data.accountStatus ? "active" : "inactive",
		};
		setLoadingCall(id);
		API.patch(`/update-user`, payload)
			.then((response) => {
				setLoadingCall("");
				showSuccessToast("Account Status changed successfully!");
				fetchUsersData("/users", "GET", { page: page, limit: 20 });
			})
			.catch((err) => {
				console.log("Error", err);
				showErrorToast(err.message || "Something went wrong");
				setLoadingCall("");
			});
	};

	const columns = [
		{
			title: "First Name",
			dataIndex: "firstName",
			key: "firstName",
			sorter: true,
		},
		{
			title: "Last Name",
			dataIndex: "lastName",
			key: "lastName",
			sorter: true,
		},
		{ title: "Email", dataIndex: "email", key: "email" },
		{
			title: "Country",
			dataIndex: "country",
			key: "country",
			sorter: false,
			// onHeaderCell: () => ({
			//   style: { whiteSpace: 'nowrap' },
			// }),
			// render: () => {
			//   return (
			//    <>
			//    <p>USA</p>
			//    </>
			//   );
			// },
		},
		{ title: "Role", dataIndex: "role", key: "role" },
		// { title: "Type", dataIndex: "type", key: "type", sorter: true },
		// {
		//   title: "Is verified",
		//   dataIndex: "isVerified",
		//   key: "isVerified",
		//   sorter: false,
		//   render: (key) => <div>{key ? "Verified" : "Not Verified"}</div>,
		// },
		{
			title: "Account Status",
			dataIndex: "accountStatus",
			key: "accountStatus",
			sorter: false,
			render: (key, data) => {
				return (
					<>
						<Switch
							style={{ marginLeft: "1em" }}
							onChange={() => handleChangeAccountStatus(data)}
							checked={data.accountStatus}
							loading={loadingCall === data.id}
						/>
					</>
				);
			},
		},
		{
			title: "Favourite Team",
			dataIndex: "favouriteTeam",
			key: "favouriteTeam",
			sorter: false,
			onHeaderCell: () => ({
				style: { whiteSpace: "nowrap" },
			}),
			render: (key) => {
				// console.log(key)
				return (
					<div className="fav-teams">
						{key.map(({ team }) => (
							<div className="fav-teams-tag">
								<img
									src={team.logo}
									alt="flag-img"
								/>
								<p>{team.name}</p>
							</div>
						))}
					</div>
				);
			},
		},
		{
			title: "Edit",
			dataIndex: "id",
			key: "id",
			render: (key) => (
				<Button
					type="primary"
					onClick={() => editUserRole(key)}
				>
					{"Edit"}
				</Button>
			),
		},
		// { title: "Country", dataIndex: "country", key: "country", sorter: true },
		// {
		//   title: "CreatedBy",
		//   dataIndex: "created_by",
		//   key: "created_by",
		//   sorter: true,
		// },
		// {
		//   title: "Edit",
		//   dataIndex: "key",
		//   key: "key",
		//   render: (key) => (
		//     <Button type="primary" onClick={() => editAttraction(key)}>
		//       {"Edit"}
		//     </Button>
		//   ),
		// },
	];

	const hideModal = () => {
		setModal(!modal);
		setUsers(null);
		fetchUsersData("/users", "GET", { page: page, limit: 20 });
	};

	useEffect(() => {
		fetchUsersData("/users", "GET", {
			page: page,
			limit: 20,
			sortBy: sortField,
			sortOrder: sortOrder,
		});
	}, [page, sortField, sortOrder]);
	const onSearch = (value) => {
		// Perform filtering based on the search value
		const filtered = countriesDataTosearch.filter((item) =>
			item.name.toLowerCase().includes(value.toLowerCase())
		);
		setCountriesDataToSearch(filtered);
	};
	let debounceTimer;
	const debounce = (func, delay) => {
		clearTimeout(debounceTimer);
		debounceTimer = setTimeout(func, delay);
	};

	const onTitleSearchDebounced = (value) => {
		debounce(() => onTitleSearch(value), 500);
	};

	const onTitleSearch = (value) => {
		if (!value) {
			setByTitle(value);
			// fetchFixtures();
			// fetchUsersData("/attractions", "GET", {
			//   page: page,
			//   limit: 20,
			//   title: value,
			// });
		} else {
			setByTitle(value);
			setPage(1);
			fetchUsersData("/users", "GET", {
				page: page,
				limit: 20,
				title: value,
			});
		}
	};

	const handleTableChange = (pagination, filters, sorter) => {
		setPage(pagination.current);
		if (sorter && sorter.field) {
			setSortField(sorter.field);
			setSortOrder(sorter.order === "ascend" ? 1 : -1);
		}
	};

	return (
		<div>
			<div
				style={{
					width: "100%",
					display: "flex",
					gap: 10,
				}}
			>
				<Search
					style={{ width: "200px", height: "31.5px" }}
					placeholder="Search By Name"
					className="search-box"
					onSearch={onTitleSearchDebounced} // Use the debounced function here
					onChange={(e) => {
						console.log("Empty Venue", e.target.value);
						if (!e.target.value) {
							console.log("Empty Venue IF", e.target.value);
							onTitleSearchDebounced("");
						}
					}}
				/>

				<Search
					style={{ width: "200px", height: "31.5px" }}
					placeholder="Search By Email"
					className="search-box"
					onSearch={onTitleSearchDebounced} // Use the debounced function here
					onChange={(e) => {
						console.log("Empty Venue", e.target.value);
						if (!e.target.value) {
							console.log("Empty Venue IF", e.target.value);
							onTitleSearchDebounced("");
						}
					}}
				/>
				{/* <Select
          mode="multiple"
          labelInValue
          value={byCountries}
          placeholder="Search By Country"
          filterOption={false}
          onSearch={onSearch}
          onChange={onCountriesChange}
          style={{ width: "20%", marginTop: "2em" }}>
          {countriesDataTosearch?.map((item) => (
            <Option key={item._id} value={item._id}>
              {`${item.name}`}
            </Option>
          ))}
        </Select> */}
				{/* <Select
          mode="multiple"
          labelInValue
          value={byType}
          placeholder="Search By Type"
          filterOption={false}
          // onSearch={onSearch}
          onChange={onTypeChange}
          style={{ width: "20%", marginTop: "2em" }}>
          {attractionTypeData?.attractionTypes?.map((item) => (
            <Option key={item._id} value={item._id}>
              {`${item.type_name}`}
            </Option>
          ))}
        </Select> */}
				{/* <Button
          type="primary"
          style={{ marginTop: "2em" }}
          onClick={() => setModal(true)}>
          Create Attraction
        </Button> */}
			</div>

			{usersData ? (
				<Table
					style={{ marginTop: "1em" }}
					columns={columns}
					dataSource={users}
					pagination={{
						pageSize: 20,
						total: total,
						showSizeChanger: false,
						current: page,
					}}
					onChange={handleTableChange}
				/>
			) : (
				<LoadingOutlined className="loader" />
			)}

			{modal && (
				<EditUserRole
					user={selectedUser}
					onClose={() => setModal(false)}
					onSave={(updatedFields) =>
						handleSaveUserChanges(selectedUser.id, updatedFields)
					}
				/>
			)}
		</div>
	);
};
