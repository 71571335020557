import React, { useState } from "react";
import { Modal, Select, Button, Input, Switch } from "antd";

const { Option } = Select;

const EditUserDetails = ({ user, onClose, onSave }) => {
	const [firstName, setFirstName] = useState(user.firstName);
	const [lastName, setLastName] = useState(user.lastName);
	const [email, setEmail] = useState(user.email);
	const [country, setCountry] = useState(user.country);
	const [role, setRole] = useState(user.role);
	const [accountStatus, setAccountStatus] = useState(user.accountStatus);
	const roles = ["admin", "user"];

	const handleSave = () => {
		onSave({ role, accountStatus });
	};

	const handleAccountStatusChange = (checked) => {
		setAccountStatus(checked);
	};

	return (
		<Modal
			title="Edit User Information"
			visible={true}
			onCancel={onClose}
			footer={[
				<Button
					key="cancel"
					onClick={onClose}
				>
					Cancel
				</Button>,
				<Button
					key="save"
					type="primary"
					onClick={handleSave}
				>
					Save
				</Button>,
			]}
		>
			<p>
				Editing information for:{" "}
				<strong>
					{user.firstName} {user.lastName}
				</strong>
			</p>

			{/* First Name Field */}
			<div style={{ marginBottom: "16px" }}>
				<label>First Name</label>
				<Input
					disabled
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
					placeholder="Enter first name"
				/>
			</div>

			{/* Last Name Field */}
			<div style={{ marginBottom: "16px" }}>
				<label>Last Name</label>
				<Input
					disabled
					value={lastName}
					onChange={(e) => setLastName(e.target.value)}
					placeholder="Enter last name"
				/>
			</div>

			{/* Email Field */}
			<div style={{ marginBottom: "16px" }}>
				<label>Email</label>
				<Input
					disabled
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					placeholder="Enter email"
				/>
			</div>

			{/* Country Field */}
			<div style={{ marginBottom: "16px" }}>
				<label>Country</label>
				<Input
					disabled
					value={country}
					onChange={(e) => setCountry(e.target.value)}
					placeholder="Enter country"
				/>
			</div>

			{/* Role Selector - Dynamically mapped */}
			<div style={{ marginBottom: "16px" }}>
				<label>Role</label>
				<Select
					value={role}
					onChange={(value) => setRole(value)}
					style={{ width: "100%" }}
				>
					{roles.map((roleOption) => (
						<Option
							key={roleOption}
							value={roleOption}
						>
							{roleOption}{" "}
						</Option>
					))}
				</Select>
			</div>

			{/* Account Status Switch */}
			<div
				style={{
					marginBottom: "16px",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<label>Account Status</label>
				<Switch
					checked={accountStatus}
					onChange={handleAccountStatusChange}
					style={{ marginLeft: "10px" }}
				/>
			</div>
		</Modal>
	);
};

export default EditUserDetails;
