import React, { useState, useEffect } from "react";
import { Input, Button, Modal, Form, Switch, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./index.scss";
import useApiRequest from "../../../helper/useApiHook";
import StarRatings from "react-star-ratings";

const EditTeams = (props) => {
	const [form] = Form.useForm();
	const [modal, setModal] = useState(false);
	const [rating, setRating] = useState();

	useEffect(() => {
		if (props.showModal) {
			setModal(true);
			if (props.team) {
				form.setFieldsValue({
					name: props.team.name || "",
					venue: props.team?.venue || "", // Use optional chaining and fallback
					website: props.team.website || "",
					color: props.team.color || "",
					color2: props.team.color2 || "",
					key: props.team.key || "",
					team_id: props.team.team_id || "",
					lat: props.team?.latitude || "",
					lng: props.team?.longitude || "",
					venueAddress: props.team?.address || "",
				});
				console.log(
					"venue",
					props.team.venue?.name || "No venue name available"
				);
			}
		}
	}, [props.showModal, props.team, form]);

	const {
		data: attractionTypeData,
		error: attractionTypeError,
		loading: attractionTypeLoading,
		makeRequest: createAttractionData,
	} = useApiRequest();

	const toggleModal = () => {
		setModal(false);
		props.hideModal(false);
	};

	const onFinish = (values) => {
		let venueData = {
			name: values.venue || null,
			coordinates: {
				lat: values.lat || null,
				lng: values.lng || null,
			},
			address: values.venueAddress || null,
		};

		let body = {
			name: values.name,
			venue: Object.keys(venueData).length > 0 ? venueData : null,
			website: values.website,
			color: values.color ? values.color.trim() : "",
			color2: values.color2 ? values.color2.trim() : "",
		};

		createAttractionData(`/league-teams-update/${props.team.key}`, "PUT", {
			...body,
			id: props.team.key,
		});
	};

	useEffect(() => {
		if (attractionTypeError) {
			message.error("Failed to update Team");
		}
		if (attractionTypeData) {
			message.success(
				`${!props.team ? "Created" : "Updated"} Team successfully`
			);
			toggleModal();
		}
	}, [attractionTypeError, attractionTypeData, props]);

	return (
		<Modal
			visible={modal}
			onCancel={toggleModal}
			footer={false}
		>
			<div className="modal-header">
				<h2>Update Team</h2>
			</div>
			<Form
				form={form}
				onFinish={onFinish}
			>
				<div>
					<label>Name:</label>
					<Form.Item name="name">
						<Input />
					</Form.Item>
				</div>
				<div>
					<label>Venue:</label>
					<Form.Item name="venue">
						<Input />
					</Form.Item>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: "10px",
					}}
				>
					<div style={{ width: "50%" }}>
						<label>Venu Latitude:</label>
						<Form.Item name="lat">
							<Input />
						</Form.Item>
					</div>
					<div style={{ width: "50%" }}>
						<label>Venu Longitude:</label>
						<Form.Item name="lng">
							<Input />
						</Form.Item>
					</div>
				</div>
				<div>
					<label>Venu address:</label>
					<Form.Item name="venueAddress">
						<Input />
					</Form.Item>
				</div>
				<div>
					<label>Color:</label>
					<Form.Item name="color">
						<Input />
					</Form.Item>
				</div>
				<div>
					<label>Color 2:</label>
					<Form.Item name="color2">
						<Input />
					</Form.Item>
				</div>
				<div>
					<label>Website</label>
					<Form.Item name="website">
						<Input />
					</Form.Item>
				</div>
				<div>
					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
						>
							Submit
							{attractionTypeLoading ? <LoadingOutlined /> : null}
						</Button>
					</Form.Item>
				</div>
			</Form>
		</Modal>
	);
};

export default EditTeams;
