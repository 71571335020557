import React, { useEffect, useState, useContext } from "react";
import {
	Table,
	Rate,
	Switch,
	Result,
	Tooltip,
	Modal,
	Button,
	AutoComplete,
	notification,
	Form,
	Row,
	Col,
	Spin,
	Input,
	Tag,
} from "antd";
import "./Teams.scss";
import { LoadingOutlined } from "@ant-design/icons";
import StarRatings from "react-star-ratings";

//COMPONENTS
import Filter from "../Filter";

// HELPER
import { STATUS } from "../../helper/status";

import { Label } from "reactstrap";
import axios from "axios";
import { Toast, ToastBody, ToastHeader } from "reactstrap";
import { isEmpty } from "lodash";
import AlertNotification from "../AlertNotification";
import StadiumForm from "../Forms/StadiumForm";
import TeamForm from "../Forms/TeamForm";
import { Context } from "../../Provider";
import API from "../../helper/api";
import useApiRequest from "../../helper/useApiHook";
import EditAttractionReview from "./editTeams";
const { Search } = Input;
export const Teams = ({ filters }) => {
	const [attractions, setAttractions] = React.useState([]);
	const [loader, setLoader] = useState(false);
	const [spinLoader, setSpinLoader] = useState(false);
	const [total, setTotal] = React.useState(0);
	const [page, setPage] = React.useState(1);
	const [modal, setModal] = useState(false);
	const [sortField, setSortField] = useState("name");
	const [byTitle, setByTitle] = useState("");
	const [byAdress, setByAdress] = useState("");
	const [byCountry, setByCountry] = useState("");
	const [sortOrder, setSortOrder] = useState(1);
	const [attraction, setAttraction] = useState(null);
	const {
		data: attractionData,
		error: attractionError,
		loading: attractionLoading,
		makeRequest: fetchAttractionData,
	} = useApiRequest();

	const fetchreviewData = () => {
		fetchAttractionData("/league-teams-dashbaord", "GET", {
			page: page,
			limit: 20,
			name: byTitle,
			venue: byAdress,
			country: byCountry,
			sortBy: sortField,
			sortOrder: sortOrder,
		});
	};
	useEffect(() => {
		fetchreviewData();
	}, []);

	useEffect(() => {
		if (attractionData) {
			const { total, teams } = attractionData;
			const data = teams?.map((item) => ({
				id: item.id,
				name: item.team.name,
				website: item.website,
				color: item.color,
				color2: item.secondaryColor,
				venue: item.venue?.name || null,
				venueCity: item.venue?.city || null,
				logo: item.team.logo,
				venueCountry: item.venue?.country || null,
				key: item._id,
				team_id: item._id,
				address: item.venue?.address || null,
				latitude: item.venue.coordinates?.lat || null,
				longitude: item.venue.coordinates?.lng || null,
			}));
			setTotal(total);
			setAttractions(data);
		}
	}, [attractionData]);
	const editAttraction = (id) => {
		const data = attractions.find((item) => id === item.key);
		setAttraction(data);
		setModal(!modal);
	};
	const columns = [
		{ title: "Id", dataIndex: "id", key: "id", sorter: true },
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			sorter: true,
			render: (name, record) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					{record.logo && (
						<img
							src={record.logo}
							alt={`${name} logo`}
							style={{ width: 30, height: 30, marginRight: 10 }}
						/>
					)}
					<span>{name}</span>
				</div>
			),
		},

		{
			title: "Website",
			dataIndex: "website",
			key: "website",
			render: (website) => {
				const formattedWebsite =
					website?.startsWith("http://") || website?.startsWith("https://")
						? website
						: `https://${website}`;

				return (
					<a
						href={formattedWebsite}
						target="_blank"
						rel="noopener noreferrer"
						style={{ color: "blue" }}
					>
						{website}
					</a>
				);
			},
		},
		{
			title: "Color",
			dataIndex: "color",
			key: "color",
			render: (color) => <span style={{ color: color }}>{color}</span>,
		},
		{
			title: "Color 2",
			dataIndex: "color2",
			key: "color2",
			render: (color2) => <span style={{ color: color2 }}>{color2}</span>,
		},
		{
			title: "Venue",
			dataIndex: "venue",
			key: "venue",
			sorter: true,
		},
		{
			title: "Venue City",
			dataIndex: "venueCity",
			key: "venueCity",
		},
		{
			title: "Team Country",
			dataIndex: "venueCountry",
			key: "venueCountry",
		},
		{
			title: "Edit",
			dataIndex: "key",
			key: "key",
			render: (key) => {
				return (
					<Button
						type="primary"
						onClick={() => editAttraction(key)}
					>
						{"Edit"}
					</Button>
				);
			},
			// sorter: (a, b) => {
			//   return b.approved - a.approved;
			// },
			// showSorterTooltip: false,
		},
	];

	useEffect(() => {
		fetchreviewData();
	}, [page, sortField, sortOrder]);

	useEffect(() => {
		if ((byAdress || byTitle || byCountry) && page === 1) {
			fetchreviewData();
		}
	}, [byAdress, byTitle, byCountry]);

	const onTitleSearch = (value) => {
		if (!value) {
			fetchreviewData();
		}
		setByTitle(value);
		setPage(1);
	};

	const onAddressSearch = (value) => {
		if (!value) {
			fetchreviewData();
		}
		setByAdress(value);
		setPage(1);
	};

	const onCountrySearch = (value) => {
		if (!value) {
			fetchreviewData();
		}
		setByCountry(value);
		setPage(1);
	};

	const hideModal = () => {
		setModal(!modal);
		setAttraction(null);
		fetchreviewData();
	};

	const handleTableChange = (pagination, filters, sorter) => {
		setPage(pagination.current);
		if (sorter && sorter.field) {
			setSortField(sorter.field);
			setSortOrder(sorter.order === "ascend" ? 1 : -1);
		}
	};

	return (
		<div>
			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<Search
					style={{ width: "200px", height: "31.5px" }}
					placeholder="Search By Name"
					className="search-box"
					onSearch={onTitleSearch}
				/>
				<Search
					style={{ width: "200px", height: "31.5px" }}
					placeholder="Search By Country"
					className="search-box"
					onSearch={onCountrySearch}
				/>
				<Search
					style={{ width: "200px", height: "31.5px" }}
					placeholder="Search By Venue"
					className="search-box"
					onSearch={onAddressSearch}
				/>
			</div>
			{attractionLoading === false ? (
				<Table
					style={{ marginTop: "3em" }}
					columns={columns}
					dataSource={attractions}
					pagination={{
						pageSize: "20",
						total: total,
						showSizeChanger: false,
						current: page,
					}}
					onChange={handleTableChange}
				></Table>
			) : (
				<LoadingOutlined className="loader" />
			)}
			<Spin
				style={{ top: "50vh" }}
				spinning={spinLoader}
			></Spin>

			{modal && (
				<EditAttractionReview
					showModal={modal}
					team={attraction}
					hideModal={hideModal}
				/>
			)}
		</div>
	);
};
